// maintenance scss
.maintenance-list-table {
  td{
    padding: 5px;
  }

  .created-date-row{
    font-size: 0.8rem;

    .person-col{
      color: #1e469a;
      padding-left: 10px;
    }
  }

  .data-group-row{
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 12px;


    .list-item-file-count{
      font-size: 0.8rem;
      color: #505050;
    }
  }
}

.maintenance-form{
  .title-label {
    color: #1e469a;
    margin-right: 10px;
  }

  .title-note-label {
    color: #1e469a;
    margin-bottom: 0;
  }

  .form-group {
    margin-bottom: 6px;
  }

  .form-label {
    color: #1e469a;
  }
  .label {
    margin-bottom: 0;
  }
  .form-control{
    background: lemonchiffon;
  }

  .form-cost-unit{
    display: flex; //!important;
    align-items: flex-end; //!important;
    margin-left: 10px;
  }

  .form-add-files{
    margin-bottom: 3px;

    .btn-add-file{
      font-size: 0.75rem;
      padding: 3px 8px;
    }

    .select-file-data{
      display: none;
    }
  }

  .maintenance-file-list-table{
    td{
      padding: 0;
    }
    .mime-type-file-icon-col{
      padding: 0.4rem;

      .mime-type-file-icon{
        width: auto;
        height: auto;
        max-width: 25px;
        max-height: 25px;
      }
    }

    .file-name{
      align-self: center;
    }
    .delete-icon-col{
      padding: 0.4rem 0.5rem;
      font-size: 1rem;

      &:hover{
        cursor: pointer;
        background: #bfbfbf;
        border-radius: 5px;
      }
    }

    .new-file-data-row{
      background: lemonchiffon;
      &:hover{
        background: #ebebeb;
      }
    }

    .exist-file-data-row .file-name:hover{
      text-decoration: underline;
    }
  }

  .form-image-group {
    margin-left: 15px;
    margin-bottom: 0px;
  }
  .form-image {
    padding: 37px;
    background: #e2e2e2;

    &:hover{
      cursor: pointer;
      background: #d0d0d0;
    }
  }

  .form-alert{
    background: #ffb9b9;
  }

  .maintenance-photo-layout{
    width: 100px;
    height: 100px;
    display: flex; //!important;
    align-items: center; //!important;

    .maintenance-photo-data{
      width: auto;
      height: auto;
      max-width: 100px;
      max-height: 100px;
    }
  }

  .delete-photo-link{
    margin: 0px;
    padding: 0px;
    font-size: 15px;
  }
}



.contact-list-tel-row{
  margin-left: 15px;
}

.contact-list-item{
  margin-left: 3px;
  margin-right: 7px;
}

.contact-list-tel-item{
  margin-right: 20px;
}

.contact-table{
  margin: 6px;

  .contact-td-title {
    color: #1e469a;
    padding-right: 15px;
  }
}

.modal-body-show-photo-data{
  //width: 100%;
  //height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  img{
    width: auto;
    height: auto;
    max-width: 100%;
    //max-height: 100px;
  }
}
