// contact scss
.contact-form{
  margin-top: 10px;

  .form-label {
    color: #1e469a;
  }

  .form-control{
    background: lemonchiffon;
  }

  .form-alert{
    background: #ffb9b9;
  }
}

.contact-list-tel-row{
  margin-left: 15px;
}

.contact-list-item{
  margin-left: 3px;
  margin-right: 7px;
}

.contact-list-tel-item{
  margin-right: 20px;
}

.contact-table{
  margin: 6px;

  .contact-td-title {
    color: #1e469a;
    padding-right: 15px;
  }
}
