.precedent-group {
  margin-left: 8px;
  font-size: 10px;

  @media (min-width: 576px){font-size: 13px;}

  .precedent-icon {
    position: unset;
    margin-top: 1.5px;
    @media (min-width: 576px){margin-top: 3px;}
  }
  .precedent-break {
    margin-right: 7px;
    @media (min-width: 576px){margin-right: 10px;}
  }
}

.main-map-content {
  height: 100%;
  margin-top: 0;
  @media (min-width: 576px){
    height: calc(100% - 50px);
    margin-top: 8px;
  }
}

.main-map-data-group {
  height: 100%;
  overflow-y: auto;
  scroll-behavior: smooth;
}

.flowmap-shutter-icon {
  display:inline-block;
  width:12px;
  height:12px;
  border-radius: 50%;
  text-align: center;
  color: white;
  position: absolute;
}

.flowmap-st-none { background-color: #666666; }
.flowmap-st-open { background-color: red; }
.flowmap-st-close { background-color: blue; }
.flowmap-st-other {
    background-color: yellow;
    color: #666666 !important;
}
.flowmap-st-alert {
  animation: flowmapAlertBlinkAnime 0.5s linear 0s infinite alternate;
  background-color: red;
}
@keyframes flowmapAlertBlinkAnime {
  0% { background-color: white; }
  100% { background-color: red; }
}
.flowmap-st-alert1 { background-color: red; }
.flowmap-st-alert2 { background-color: white; }

.main-map-content-image {
  height: 100%;
  position: relative;

  img {
    width: 100%;
    height: calc(100% - 23px);
    margin-left: 5px;
    box-shadow: 1px 1px 2px 0px #9e9e9e;
    z-index: 1;
    @media (min-width: 576px){ margin-left: 8px; }
  }

  .dummy-event-map{
    position: absolute;
    top: 0px;
    left: 0;
    z-index: 2;
    margin-left: 5px;
    @media (min-width: 576px){ margin-left: 8px; }
  }

  .main-map-loading{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    margin-left: 8px;

    .loading-back{
      background: #e0e0e0;
      opacity: 0.7;
      width: 100%;
      height: 100%;
    }

    .loading-text{
      position: absolute;
      top: 40px;
      left: 10px;
    }
  }

  .main-map-shutter-mark {
    position: absolute;

    .shutter-mark {
      border-radius: 2px;
      background-color: #00ff00;
  	}
  	.shutter-mark-alert {
     background-color: #ff3e3e;
  	}
  }

  .main-map-mark-eco {
    position: absolute;
    box-shadow: 1.5px 1.5px 2px 0px rgba(0, 0, 0, 0.5);
  }

  .main-map-mark-drive-mode {
    box-shadow: 1.5px 1.5px 2px 0px rgba(0, 0, 0, 0.5);
  }

  .flowmap-factory-link,
  .flowmap-shutter-link {
    position: absolute;
    left: 0;
    top: 0;
    padding-top: 3px;

    .flowmap-factory-mark:hover {
      background-color: #ffffff;
      -khtml-opacity: 0.4; /* Safari 1.x */
      opacity: 0.4; /* chrome */
      filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)"; /* For IE */
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)"; /* For IE */
    }

    .flowmap-factory-alert {
      background-color: red;
      -khtml-opacity: 0.4; /* Safari 1.x */
      opacity: 0.4; /* chrome */
      filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)"; /* For IE */
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)"; /* For IE */
      animation: flowmapFactoryAlertBlinkAnime 0.5s linear 0s infinite alternate;
    }

    @keyframes flowmapFactoryAlertBlinkAnime {
      0% { background-color: transparent; }
      100% { background-color: red; }
    }

    &:hover{ cursor: pointer; }
  }

  .flowmap-sensor-item {
    position: absolute;
    color: white;
    background: #1a76d2;
    padding: 2px 4px;
    border: 1.5px solid #e5e5e5;
    border-radius: 2px;
    box-shadow: 1px 1px 4px 0 #292929;

    .flowmap-sensor-item-data{
      background: black;
      margin-left: 4px;
      margin-right: 4px;
      font-weight: bold;
      padding: 2px 3px 2px 15px;
    }
  }
}

.popover{
  .popover-header { font-size: 0.95rem; }
  .h-pos-mode{
    //background-color: #ffe07f;
    font-size: 0.85rem;
    padding: 0.4rem 0.5rem;
  }
  .popover-content {
    height: 115px;
    width: 265px;
    padding: 6px;
    font-size: 12px;

    .menu-link {
      padding: 0;
      font-size: 12px;
      font-weight: 400;
      color: #007bff;
      text-decoration: none;

      &:hover{
        color: #0d47a0;
        cursor: pointer;
        text-decoration: underline;
      }
    }

    .btn-sm {
      padding: 0;
      font-size: 13px;
    }
    .popover-content-photo {
      text-align: center;

      img{
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 65px;
      }
    }
    .popover-content-data {
      padding-left: 6px;
    }
  }

  .popover-pos-mode-content {
    padding: 6px;
    font-size: 0.8rem;
    width: 205px;

    .spinner-border{
      width: 1.4rem;
      height: 1.4rem;
    }

    .btn-common{
      font-size: 0.7rem;
      padding: 0.25rem 0.4rem;
    }

  }
}


.popover-detail-link {
  margin-top: 5px;
  text-align: center;
}

.main-map-content-list {
  height: 100%;
  overflow-y:auto;
}

.main-contents-layout{
  background: white;
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 5px;
  border-radius: 5px;
  box-shadow: 1px 1px 2px 0px #8c8c8c;
  border: 0;

  @media (min-width: 576px){ 
    margin-right: 8px;
    margin-left: 8px;
  }

  .card-header-common{
    padding: 5px 10px;
    font-size: 1rem;
    position: sticky;
    top: 0px;
    background-color: #f3f3f3;
    z-index: 1;
  }

  .card-header-common2{
    padding: 5px 10px;
    font-size: 1rem;
    background-color: #f3f3f3;
  }

  .card-body-common{

    margin-right: 10px;
    margin-left: 10px;
    font-size: 0.9rem;

    .loading{
      margin: 0;
    }
    .btn-common {
      font-size: 0.8rem;
      padding: 5px 12px;
    }

    .table-hover{
      tbody tr:hover{
        cursor: pointer;
      }
    }

    .table-common {
      margin-bottom: 5px;
    }

    .content-header{
      position: sticky;
      top: 35px;
      background-color: white;
      padding-top: 5px;
      padding-bottom: 5px;
      border-bottom: 1px solid rgba(0,0,0,.125);
      z-index: 1;
    }

    .content-body{
      padding: 5px;
    }
    .content-no-data{
      padding: 8px;
    }

    .result-message{
      color: #28a745;
      margin-right:15px;
    }

    .failure-message{
      color: #dc3545;
      margin-right:15px;
    }
  }
}

.map-facility-list {
  table{
    .fixed-th{
      position: sticky;
      top: -1px;
      &:before{
        content: "";
        position: absolute;
        top: -1px;
        left: -1px;
        width: 100%;
        height: 100%;
        border-top: 1px solid #e0e0e0;
      }
    }
  }

  .table-hover{
    tbody tr:hover{
      cursor: pointer;
    }
  }

  .new-shutter-icon{
    width: auto;
    height: auto;
    max-width: 20px;
    max-height: 20px;
    padding: 2px;
    vertical-align: text-top;

    &:hover{
      background: #d2e3fc;
      border-radius: 3px;
      cursor: pointer;
    }
  }

  .map-facility-table {
    font-size: 0.7rem;

    td, th{
      padding: 0.45rem;
    }

    .th-radius-left{
      border-top-left-radius: 4px;
    }

    .th-radius-right{
      border-top-right-radius: 4px;
    }

    tbody td{
      vertical-align: inherit;
    }

    .status-icon{
      width:16px;
      height:16px;
      border-radius: 50%;
      text-align: center;
      color: white;
    }

    .facility-info{
      margin-right: 5px;
    }

    .item-photo{
      width: 65px;
      text-align: center;

      img{
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 65px;
      }
    }

    .item-facility-name{
      font-size: 0.9rem;
      color: #0d47a0;
    }

    .item-info1, .item-info2, .item-info-count{
      span {display: inline-block;}
      span.item-title{ width: 50px; }
      span.item-title-mainte{ width: 62px; }
      span.item-title-drcount{
        margin-right: 5px;
      }

      div{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .edit-icon{
      text-align: right;
      align-self: center;

      img{
        width: auto;
        height: auto;
        max-width: 25px;
        max-height: 25px;

        &:hover{
          background: #c5cfdd;
          border-radius: 2px;
          cursor: pointer;
          padding: 2px;
        }
      }
    }
  }
}

.main-map-modal-header{
  padding: 0.8rem;

  .main-map-modal-title{
    font-size: 1.1rem;
  }
}

.main-map-modal-body{
  padding: 0.8rem;

  .del-body{ padding: 0.5rem 1.1rem; }
  .main-map-photo-group{
    border: 1px #c1c1c1 solid;
    border-radius: 2px;

    label{
      display: block;
      margin-bottom: 0;
    }
    .main-map-photo-layout{
      background: #e2e2e2;

      &:hover{
        cursor: pointer;
        background: #d0d0d0;
      }

      .main-map-photo-data{
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 152px;
        border-radius: 2px;
      }

      .photo-data-select:hover{
        background: #e0e0e0;
        opacity: 0.7;
      }
    }
  }

  .form-alert{
    background: #ffb9b9;
  }

  .modal-item-size-x{
    text-align: center;
    font-size: 18px;
  }

  .btn-ch-pos{
    padding: 0.375rem 0.75rem;
    font-size: 0.8rem;
  }
}

.main-map-modal-footer{
  padding: 0.4rem;

  button {
    font-size: 0.8rem;
  }

  .loading{
    margin: 0;
  }
}
