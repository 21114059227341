.setting-content-group {
  padding: 10px 0;
  margin-right: 5px;
  margin-left: 5px;
}

.setting-item-col {
  border-radius: 5px;
}

.setting-func-item {
  margin-right: 5px;
  margin-left: 5px;
  align-items: center !important;
  border-bottom: 1px solid #cfd3d6;

  .setting-func-no {
    width: 48px;
    text-align: center;
  }

  .setting-func-name {
    overflow: hidden;

    div{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.setting-item-selected {
  color: #ffffff !important;
  background-color: #28a745 !important;
}

.setting-func-item:hover {
  cursor: pointer;
  background-color: #dddddd;
}

.setting-result {
  display: inline-table;
  border: 1px solid;
  border-radius: 5px;
  padding: 5px;
  margin: 0 8px 8px 8px;
}
