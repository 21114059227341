// report scss
.report-header-layout{
  position: sticky;
  top: 0;
  z-index: 1;
  margin-bottom: 8px;
}

.report-select-date-layout{
  align-items: center;
  margin-top: 8px;
  margin-bottom: 8px;

  .form-select-col{
    align-items: center;
    margin-right: 8px;
    select{
      margin-right: 2px;
      background: #ffffcc;
    }
  }
}

.report-data-type-layout{
  margin-top: 8px;

  .btn-data-tab{
    font-size: 0.85rem;
    padding: 3px 15px;
  }

  .disp-report-date{
    color: #0d47a0;
    text-align: right;
    align-self: end;
    font-weight: bold;
  }

  select{
    margin-left: 25px;
    background: #ffffcc;
    height: calc(1.5em + 0.5rem);
  }
  .form-control:focus{
    background: #ffffcc;
  }
}

.content-report-data, .rp-modal-body{
  display: block;
  width: 100%;
  height: 100%;
  overflow-x: auto;
  overflow-y: auto;
  --webkit-overflow-scrolling: touch;
  font-family: Verdana, Helvetica;
  margin-top: 2px;
  margin-bottom: 5px;

  .rp-table{
    width: auto;
    color: #212529;
    font-size: 0.8rem;
    text-align: right;
    box-shadow: 1px 1px 2px 0px #8c8c8c;

    .rp-h-cell{ height: 28px; line-height: 28px; }
    .rp-h-cell-sm{ height: 24px; line-height: 24px; }

    tr.rp-header-record td{
      border-top: 0px;
      padding: 0px;
    }

    td{
      border-top: 1px solid #bbbbbb;
      border-left: 1px solid #bbbbbb;
    }

    .l-b-1{
      border-bottom: 1px solid #bbbbbb;
    }

    .rp-h-item-title {
      background: #333333;
      color: white;

      div { width: 85px; }
    }

    .rp-h-daily-dr {
      color: white;
      background: #0d489f;

      td:first-child{
        background: #333333;
      }

      td{
        border-left: 0px;
        height: 28px;
        line-height: 28px;
        text-align: center;
      }

    }

    .rp-h-data {
      background: #ffffff;

      .rp-h-d-val{
        padding-left: 0.3rem;
        padding-right: 0.3rem;
      }
      .rp-h-d-w-ai{ width: 200px; }
      .rp-h-d-w-pi{ width: 200px; }
      .rp-h-d-w-dr{ width: 185px; }
      .rp-h-d-title{
        background: #0d489f;
        color: white;
        border-bottom: 1px solid #bbbbbb;
        padding: 0 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
      }
      .rp-h-d-edit-icon{
        background: #feffcb;
        color: white;
        border-top: 1px solid #bbbbbb;
        border-bottom: 1px solid #bbbbbb;
        text-align: center;

        img{
          width: auto;
          height: auto;
          max-width: 24px;
          max-height: 24px;
          margin-top: -5px;
        }

        &:hover{
          background: #ffc592;
          cursor: pointer;
        }
      }

      .rp-h-d-item{
        text-align: center;
      }

      .rp-h-d-category{
        background: #666666;
        color: white;
        text-align: center;
      }
    }

    .rp-p-data {
      td:first-child{
        background: #333333;
        color: white;
      }
      td.w-sat{ background: #6677cc; color: white;}
      td.w-sun{ background: #e69191; color: white;}　

      .rp-p-data-name{
        text-align: left;
      }

      .py-sm{
        padding-top: 0.1rem; padding-bottom: 0.1rem;
      }
    }

    tr.change-bk-color{ background: #f1f1f1; }

    .rp-edit{
      color: #ff8820;
    }
  }

  .rp-table td:first-child{
    text-align: center;
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    z-index: 1;
    border-left: 1px solid #bbbbbb;
  }

  .rp-table tr:first-child{
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 3;
  }

  .rp-table tr:last-child{
    border-bottom: 1px solid #bbbbbb;
  }

  .rp-daily-dr-tbl{width: 100%;}
}

.report-alert-message{
  margin: 20px;
  text-align: center;
}

.rp-modal-body{
  padding: 0 0.4rem;
  margin: 0.4rem 0;

  .font-title{ font-size: 0.9rem; }
  .font-title-comment{ font-size: 0.6rem; }
  .ch-dr-count-monthly{ color: #ff8700; }
  .getting-value{
    color: #0d489f;
    text-align: left;
  }
  .edit-input-form, .edit-select-form{
    text-align: right;
    background: #ffffcc;
  }

  .edit-rp-dr-count{
    width: 100px;
    margin-left: -1px;
  }

  .edit-rp-ch-type-btn{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    z-index: 2;
  }
}
