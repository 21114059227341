// login scss
.main-layout {
  background-color: #f9f9f9;
  margin: 40px;

  .data-layout {
    padding: 10px;
  }
}

.login-layout {
  display: inline-block;
  height: 100%;
  width: 100%;
  background-image: url("../images/monban_flatsheet_transparent.png");
  background-blend-mode:lighten;
  background-size: contain;
  background-repeat:  no-repeat;
  background-position: center -5px;
}

.login-box {
  background-color: #f9f9f9;
  margin-top: 40px;
  border-radius: 5px;
  box-shadow: 3px 3px 12px 0px rgba(0, 0, 0, 0.25);

  .hp-link-text {
    padding-top: 8px;
    padding-bottom: 6px;
  }

  .login-field {
    text-align: left;
    margin-left: 10px;
    margin-right: 10px;

    label { margin-bottom: 0; }
    input { width: 100%; }
  }

  .login-failure {
    color: red;
    padding-top: 5px;
  }
}
