.top-content {
  overflow-y: auto;
  height: 100%;

  .card-body-common{
    height: 135px;
  }

  .item-di-trend{
    box-shadow: 1px 1px 2px 0px #8c8c8c;
    margin: .25rem;

    .item-di-trend-title{
      padding: 5px;
      color: #0d47a0;
      font-weight: bold;
    }

    .item-di-trend-data{
      padding: 5px;

      .di-trend-canvas{
        width: 100%;
        height: 80px;
      }
    }

  }

  .item-ai-trend{
    box-shadow: 1px 1px 2px 0px #8c8c8c;
    margin: .25rem;

    .item-ai-trend-title{
      padding: 5px;
      color: #0d47a0;
      font-weight: bold;
    }
  }

  .item-sensor{
    box-shadow: 1px 1px 2px 0px #8c8c8c;
    color: white;
    margin: .25rem;

    .item-sensor-title{
      padding: 5px;
    }
    .item-sensor-data{
      text-align: center;
      line-height: 135px;
    }
  }
  .sensor-color{ background: linear-gradient(#6dcbae, #9dd7d3); }
  .sensor-color-1{ background: linear-gradient(#6dcbae, #9dd7d3); }
  .sensor-color-2{ background: linear-gradient(#487dd7, #95b4e8); }
  .sensor-color-3{ background: linear-gradient(#dd78a0, #df8f70); }
}

.di-trend-v-area{
  background: linear-gradient(#0074d0, #073ea9);
  color: white;
  padding: 4px 0px;
  margin-top: 3px;
  border-radius: 3px;
  font-size: 0.7rem;
  @media (min-width: 576px){ font-size: 0.875rem; }

  .di-trend-v-title{
    margin-left: 3.5px;
    border-right: 1px white solid;
    padding-right: 3.5px;
    padding-top: 3px;
    padding-bottom: 3px;
    @media (min-width: 576px){ 
      margin-left: 5px;
      padding-right: 5px;
    }
  }
  .di-trend-v-value{
    float: right;
    margin-right: 3.5px;
    padding-top: 3px;
    padding-bottom: 3px;
    @media (min-width: 576px){ margin-right: 5px; }

    .di-trend-v-value-font{
      color: #94f8ff;
      font-weight: bold;
    }
  }
}

.di-trend-v-area-space{
  margin-left: 8px;
  margin-right: 8px;
}

.di-trend-area{
  height: 100%;
  padding: 5px;

  .di-trend-title{
    color: #0d47a0;
    font-weight: bold;
  }

  .di-trend-canvas{
    width: 100%;
    //height: 50%;
  }
}
