.shutter-info-header {
  position: relative;

  a {
  	margin-right: 10px;
  	position: absolute;
  	bottom: 0;
  	right: 0;
  	border-radius: 3px;
  	padding: 2px;
  	z-index: 1;
  }
  a:hover {
    background-color: #c7c7c7;
  }
}

.shutter-info-content {
  padding-right: 10px;
  padding-bottom: 5px;
  margin-top: 5px;
}

.shutter-info-content-group {
  padding: 6px;

  .group-data {
    margin-left: 0px;
  }

  .s-now-info-group{
    background-color: #81cc29;
    border-radius: 3px;
    color: white;
    margin: 4px;

    .s-now-info-height{
      height: 100%;
      min-height: 98px;
    }

    .s-now-info-item {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      height: max-content;
    }
    .now-info-item-title {
      font-size: 30px;
    }
    .now-info-item-data {
      font-size: 30px;
      font-weight: bold;
    }
  }

  .s-status-group{
    margin: 4px;
    background: black;
    border-radius: 3px;

    .status-group-data {
      height: 95px;
      border-radius: 3px;
      color: white;
    }

    .status-item-title {
      font-size: 16px;
      line-height: 35px;
    }
    .status-item-data {
      line-height: 55px;
      font-size: 18px;
      font-weight: bold;
    }

    .status-color-green { background-color: #81cc29; }
    .status-color-red,  .status-color-open  { background-color: #b71c1c; }
    .status-color-blue, .status-color-close { background-color: #1a76d2; }
    .status-color-none {
      background-color: #666666;
      color: #cccccc;
    }
    .status-color-other {
        background-color: #f1f100;
        color: #666666;
    }
    .status-opacity {
      opacity: 0.7;
    }
  }

  .device-alert {
    display: inline-block;
    background-color: #b71c1c;
    width:100%;
    color: white;

    &:hover {
      cursor: pointer;
      background-color: #960a0a;
      text-decoration: none;
      border-radius: 3px;
    }
  }
  .toggle-sw-item-text {
    color: #4a4a4a;
    font-size: 0.92rem;
  }

  .s-parts-list-btn{
    margin-top: 3px;
  }

  .shutter-picture{
    display: inline-block;
    position: relative;

    .shutter-picture-img{
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 150px;
    }

    .save-shutter-photo-form{
      position: absolute;
      bottom: 0;
      right: 0;

      label{
        margin-bottom: 0;
      }

      .save-photo-icon{
        display:flex;
        position: absolute;
        bottom: 5px;
        right: 5px;
        background: #28a745;
        border-radius: 50%;
        width: 30px;
        height:30px;
        cursor: pointer;
        align-items: center;
        justify-content: center;
      }
      .save-photo-icon:hover {
        background: #218838;
      }

      .select-photo-file{
        display: none;
      }
    }
  }

  .save-new-shutter-photo-form{
    label{
      margin-bottom: 0;
    }

    .save-camera-image{
      height: 150px;
      cursor: pointer;
    }
    .save-camera-image:hover {
      background: #e4e4e4;
    }
    .select-photo-file{
      display: none;
    }
  }

  .update-picture-toast{
    background: #ffffb6;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
  }

  .di-trend-canvas{
    height: 80px;
  }
}

.shutter-info-analog-group {
  .item-ai-trend {
    //このクラスはシャッター情報画面にのみ適用する
    margin-left: 8px;
    margin-bottom: 8px;
    box-shadow: 1px 1px 2px 0px #9e9e9e;
    border: 0px;
    border-radius: 0px;
  }

  .item-ai-trend-title {
    padding: 5px;
    color: #0d47a0;
    font-weight: bold;
  }
}

.shutter-product-info {
  margin-left: 5px;
  font-size: 0.9rem;
}

.shutter-info-content-header {
  color: #0d47a0;
  font-weight: bold;
  margin-left: 5px;
}

.shutter-device-data-color {
  color: #06b135;
}


.shutter-info-table, .shutter-alert-table,
.shutter-product-table, .shutter-parts-table {
  margin-top: 2px;
  margin-bottom: 0;
  td{
  	border: 0;
  	padding: 0.1rem;
  }

  a.alert {
    color: #ff3e3e;
  }

  td.safe {
    color: #06b135;
  }
}

.shutter-parts-table td{
  padding-left: 4px;
  padding-right: 4px;
}

.shutter-info-parts-border {
  padding-top: 6px;
  border-bottom: 1px solid #cfd3d6;
}

.shutter-parts-area{
  overflow-y: auto;
  height: 63%;
}
.shutter-parts-table {
  margin-top: 6px;
}

.shutter-alert-info {
  .safe {
	  color: #06b135;
  }
}

.shutter-alert-table {
  .thead-alert th{
  	color: #ffffff;
  	background-color: #ff3e3e;
  	text-align: center;
  }
}

.shutter-alert-text-color {
  color: #ff3e3e;
}

.shutter-alert-item-messege {
  margin-left: 15px;
  margin-bottom: 15px;
}

#shutter-setting-table-header {
  color: #212529;
  font-weight: bold;
  padding: 0.3rem;
  border-bottom: 2px solid #cfd3d6;
}

.shutter-setting-data-content {
  overflow-y: auto;

  div.border-right {
    border-right: 2px solid #cfd3d6;
  }
}

.shutter-setting-table {
  .border-none td {
    border: 0;
  }

  td:hover {
    cursor: pointer;
  }
}

.shutter-sensor-status {
  margin-left: 8px;
  font-size: 14px;

  span.status-icon, span.status-icon-on {
    display: inline-block;
    line-height: 14px;
    width: 14px;
    border-radius: 50%;
    margin-right: 4px;
  }

  span.status-icon {
    background: #a5a5a5;
  }
  span.status-icon-on {
    background: #00ae00;
  }

  div.status-text {
    color: #6f6f6f;
  }
  div.status-text-on {
    color: #009f00;
  }
}

.parts-list-header-item{
  border-top: 0px !important;
}

.shutter-control-btn-bk {
  background: gray;
  height: 26px;
  border-radius: 50%;

  .shutter-control-btn {
    height: 100%;
    margin-bottom: 1px;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }
}

.info-list-header {
  display: block;
  font-weight: bold;
  padding:  8px;
  color: #4a4a4a;
  position: sticky;
  top: 0;
  height: 40px;
  background: #e0e0e0;
}

.info-list-content {
  background: white;
  margin-right: 8px;
  margin-left: 8px;
  font-size: 0.88rem;
  border-radius: 5px;
  box-shadow: 1px 1px 2px 0px #8c8c8c;


  table{
    .fixed-th{
      position: sticky;
      top: 40px;
      &:before{
        content: "";
        position: absolute;
        top: -1px;
        left: -1px;
        width: 100%;
        height: 100%;
        border-top: 1px solid #e0e0e0;
      }
    }
    /*
    thead, tbody {
      display: block;
    }
    tbody {
      overflow-x: hidden;
      overflow-y: scroll;
      height: 300px;
    }
    */
  }
}
