// finder scss
.finder-list-table {
  td{
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .finder-list-created-date-row{
    font-size: 0.8rem;

    .finder-list-person-col{
      color: #1e469a;
      padding-left: 10px;
    }
  }

  .finder-list-data-row{
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 12px;
  }  
}
