@import "Top";
@import "Login";
@import "MainMap";
@import "Shutter";
@import "Setting";
@import "Contact";
@import "Finder";
@import "Maintenance";
@import "Report";
@import "Inspection";

html, body, #root, #App {
  height: 100%;
}

.app-header {
  padding-top: 45px;
}

.main-bkcolor {
  background: #e0e0e0;
}

.app-footer {
  padding-bottom: 20px;
}

.loading {
  margin-top: 20px;
  .loading-title{
    padding-left: 5px;
  }
}

.fill-height {
  height: 100%;
}

.no-padding {
  padding: 0;
}
.margin-left-5 {
  margin-left: 5px;
}
.margin-right-5 {
  margin-right: 5px;
}
.margin-left-3 {
  margin-left: 3px;
}
.margin-right-3 {
  margin-right: 3px;
}
/*
 * header
 */
.header {
  height: 45px;
  background-color: #0d47a0;
  z-index: 1100;

  .h-vertical{
    height: -webkit-fill-available;
    line-height: 40px;
  }

  .h-text-rogo {
    color: #bbdefb;
    font-weight: bold;
    font-size: 16px;
    line-height: 45px;
    margin-left: 5px;
    margin-right: 5px;

    @media (min-width: 576px){ 
      margin-left: 10px;
      font-size: larger; 
    }
    .rogo-link:hover{cursor: pointer;}
  }

  .h-menu{
      color: #94f8ff;
  }

  .h-menu-line-left {
      border-left: 1px solid #6296d0;
  }
  .h-menu-line-right {
      border-right: 1px solid #6296d0;
  }
  .h-menu-btn {
      display: inline-block;
      height:43px;
      width:100%;
      background-color:transparent;
      border-color:transparent;
      color: #94f8ff !important;
      padding: 0 5px;
      font-size: 0.7rem;

      @media (min-width: 576px){ 
        padding: 0 10px;
        font-size: 0.8rem; 
      }

      &:hover, &:focus, &:active {
        text-decoration: underline;
        background-color: #016ac8 !important;
      }
  }

  .select-company{
    margin-top: 7px;
    width: 135px;
    white-space: nowrap;
    text-overflow: ellipsis;

    @media (min-width: 576px){ 
      width: auto;
      margin-top: 7px;
    }
  }

  .dropdown-item {
      font-size: 0.8rem;
      line-height: 28px;
  }
}

.footer {
  width: 100%;
  background-color: #e0e0e0;
  color: #616161;
  text-align: right;
}

.leftcolor-blue {
    padding-left: 10px !important;
    border-left-width: 5px !important;
    border-left-style: solid !important;
    border-left-color: #6495ed !important;
}

.shutter-list-main {
  background-color: #016ac8;
  overflow-y: auto;
  height: 100%;
  padding-left: 5px;
  padding-right: 5px;

  .s-list-item {
    background-color: transparent;
    color: white;
    padding: 0.3rem 0.8rem;
    border-bottom: 1px #55a9fb solid;
    cursor: pointer;

    &:hover{
      background-color: #00509a;
    }
  }

  .s-factory-item {
      color: #94f8ff;
      padding-left: 0.3rem;

      &:hover{ color: #94f8ff; }
  }

  .s-item-selected {
      background-color: #34a4ff !important;
  }
  .s-item-alert {
      color: #ff4343;
      font-weight: bold;
  }
}

.selected-menu-title {
    font-weight: bold;
    margin-top: 8px;
    margin-left: 8px;
    color: #4a4a4a;
}

.function-header {
    display: block;
    font-weight: bold;
    margin: 8px;
    color: #4a4a4a;
}

.main-content {
  background: #e0e0e0;
  height: calc(100% - 60px);
  //height: auto; 

  @media (min-width: 576px){ height: 100%; }

  .nav-tabs {
    margin-top: 8px;
    margin-bottom: 5px;
    padding-left: 5px;
    border-bottom: 2px solid #0d47a0;

    a {color: #808080;}

    .nav-link{
      padding: 6px 16px;
      border: 1px solid #afafaf;
      border-bottom-color: #0d47a0;
    }
    .nav-link.active{
      color: #0d47a0;
      background-color: white;
    }
  }

  .tab-content{height: calc(100% - 115px);}
  .body-content {
    height: calc(100% - 43px);
  }

}

.main-content.select-map {
  height: calc(100% - 60px);
}

.main-header{
  padding-top: 4px;
  padding-bottom: 4px;
  position: sticky;
  z-index: 100;
  border-bottom: 2px solid #0d47a0;

  .select-title {
    margin-top: 5px;
    margin-left: 5px;
    font-size: 0.75rem;
    white-space: nowrap;

    @media (min-width: 576px){ 
      margin-top: 3px;
      margin-left: 8px; 
      font-size: 1rem;
    }
  }

  .target-shutter{
    margin-left: 5px;
    font-size: 0.85rem;
    white-space: nowrap;
    align-content: center;
    padding-top: 3px;
    padding-left: 0;

    @media (min-width: 576px){ 
      font-size: 1rem;
      margin-left: 8px;
      padding-top: 0;
      padding-left: 13px;
    }

    .selected-shutter{
      color: #0d47a0;
    }
  }

  select{
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 0.75rem;
    margin-right: 6px;
    max-width: 130px;
    padding: 0.25rem 0.25rem;
    
    @media (min-width: 576px){ 
      font-size: 0.875rem;
      margin-right: 15px;
      max-width: 210px;
      padding: 0.25rem 0.5rem;
    }
  }
}

.display-none{
  display: none;
}

/*
 * Toggle Switch
 */
.toggle-switch {
    position: relative;
    width: 54px;
    height: 22px;
    margin: auto;

    input {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 5;
        opacity: 0;
        cursor: pointer;
    }

    label {
        width: 44px;
        height: 22px;
        background: #ccc;
        position: relative;
        display: inline-block;
        border-radius: 24px;
        transition: 0.4s;
        box-sizing: border-box;

        &:after {
            content: '';
            position: absolute;
            width: 22px;
            height: 22px;
            border-radius: 100%;
            left: 0;
            top: 0;
            z-index: 2;
            background: white;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
            transition: 0.4s;
        }
    }

    input:checked {
        +label{
            background-color: #4BD865;

            &:after{
                left: 22px;
            }
        }
    }
}
.modal{ z-index: 1500;}
.notice-modal-footer{
  padding: 5px;
  div { padding: 0; }
  .select-photo-file{ display: none; }
}

.dropdown-item:hover{
  background: #e8e8e8;
}

.input-unit, .input-unit-r{
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -1px;
}

.input-unit-l{
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-right: -1px;
}

.text-navy{
  color: #0d47a0;
}

.btn-navy{
  color: #ffffff;
  background-color: #0d47a0;
  border-color: #0d47a0;
  &:hover{
    color: #ffffff;
    background-color: #073478;
    border-color: #073478;
  }
}

.btn-outline-navy{
  color: #0d47a0;
  border-color: #0d47a0;

  &:hover{
    color: #ffffff;
    background-color: #073478;
    border-color: #073478;
  }
}

.menu-content{
  background-color: #ffffff;
  height: 60px;
  box-shadow: 0px 1px 2px 0px #8c8c8c;
  z-index: 1070;
  position: sticky;
  top: 45px;

  @media (min-width: 576px){ 
    height: 100%;
    position: static;
    box-shadow: 1px 0px 2px 0px #8c8c8c;
  }

  .menu-list{
    width: 100%;
    display: flex;
    overflow-x: auto;
  
    @media (min-width: 576px){
      width: 64px; 
      display: block;
      overflow-x: unset;
    }
  
    .menu-item{
      text-align: center;
      align-self: center;
      margin-top: 2px;
      margin-right: 2px;
      @media (min-width: 576px){ 
        margin-top: 7px; 
        margin-right: 0;
      }
  
      &:first-child{
        margin-left: 2px; 
        @media (min-width: 576px){ 
          margin-left: 0; 
        }
      }
  
      img{
        width: auto;
        height: auto;
        max-width: 38px;
        max-height: 38px;
        padding: 7px;
  
        &:hover{
          background: #d2e3fc;
          border-radius: 3px;
          cursor: pointer;
        }
      }
  
      img.selected{
        background: #d2e3fc;
        border-radius: 3px;
      }
  
      .menu-text{
        color: #0d47a0;
        font-size: 0.7rem;
        width: 58px;
        @media (min-width: 576px){ width: auto;}
      }
    }
  }
}