// inspection scss
.inspection-card-header{
  position: sticky;
  top: 40px;
}
.inspection-card-header{
  position: sticky !important;
  top: 45px !important;
}
.inspection-content-header{
  position: sticky !important;
  top: 80px !important;
}
.inspection-list-table {
  td{ padding: 0; }
  .item-col{ padding: 0.5rem 0rem 0.5rem 0.75rem; }

  .title{
    color: #1e469a;
    padding-right: 8px;
  }

  .value{
    color: #1e469a;
    padding-left: 10px;
  }
  .download-icon{
    align-self: center;
    padding-right: 0.5rem;

    img{
      width: auto;
      height: auto;
      max-width: 32px;
      max-height: 32px;

      &:hover{
        cursor: pointer;
        background: #c5cfdd;
        border-radius: 2px;
        padding: 3px;
      }
    }
  }
}

.inspection-body{ padding: 0 15px 5px 15px; }
.inspection-body-sm{ padding-bottom: 5px; }
.inspection-body, .inspection-body-sm{
}

.inspection-header{
  padding:  5px 20px;
  .base-info{ font-size: 0.8rem; }
  .input-group-text{ font-size: 0.8rem; }
  .title-cheker {
    div{
      padding-top: calc(0.375rem + 1px);
      padding-bottom: calc(0.375rem + 1px);
      margin-bottom: 0;
      font-size: inherit;
      line-height: 1.5;
    }
  }
  .title-label {
    color: #1e469a;
    margin-right: 10px;
  }

  .subtitle-label {
    color: #1e469a;
    margin-right: 6px;
  }
  .form-label {
    width: 80px;
    color: #1e469a;
  }
  .form-group { margin-bottom: 1px; }
  .label { margin-bottom: 0;}
  .form-control{
    background: lemonchiffon;
    font-size: 0.8rem;
    padding: 0 0.75rem;
  }
  .form-alert{ background: #ffb9b9; }

  .inspection-report-table{
    td, th{padding: 0.2rem;}
    .section{background: #ccc;}
    .content-td{padding-left: 10px;}
    .item-title{color: #1e469a;}
    .option-memo{
      button{
        font-size: 0.85rem;
        padding: 0.1rem 0.4rem;
      }
    }
  }

  .section-detail{
    background: #ccc;
    padding: 0.2rem;
  }
}

.inspection-contents{
  padding:  0 5px;

  .contents-header{
    background: #cccccc;
    div {text-align: center; }
  }

  .contents-item{
    padding: 4px 0;
    border-bottom: 1px solid #dee2e6;

    .check-title{
      padding-left: 8px;
      padding-right: 3px;
    }
    .check-status{
      text-align: center;
    }
  }

  .form-control{
    background: lemonchiffon;
    font-size: 0.85rem;
    padding: 0.3rem 0.75rem;
  }

  .detail-header{
    background: #cccccc;
    padding-left: 10px;
  }
  .detail-category{border-top: 1px solid #dee2e6;}
  .detail-title{ padding: 8px 8px 3px 8px; }

  .detail-item{
    padding: 3px 0;

    .detail-imgtitle{ padding: 5px 14px 2px 14px; }
    .select-photo-area{
      text-align: center;

      img{
        cursor: pointer;
        background: #ccc;
        width: auto;
        height: auto;
        max-width: 91%;
        max-height: 165px;
        cursor: pointer;
      }
      img:hover {
        opacity: 0.65;
      }

      .select-photo-file{ display: none; }
    }
  }
  .add-detail-area{
    margin-top: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
  }

  .touch-mobile{
    .btn-common{ padding: 8px 14px; }
    .btn-outline-success{
      color: #28a745;
      background: transparent;
    }
    .btn-outline-warning{
      color: #ffc107;
      background: transparent;
    }
    .btn-outline-danger{
      color: #dc3545;
      background: transparent;
    }
    .btn-outline-primary{
      color: #007bff;
      background: transparent;
    }
    .btn-outline-secondary{
      color: #6c757d;
      background: transparent;
    }

    .form-control { font-size: 1rem; }
  }

}

.inspection-modal-footer{
  .select-photo-file{ display: none; }
}
